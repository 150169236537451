<template>
  <b-modal
    v-model="modal"
    :title="$t('label.filter')"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    scrollable
    header-class="text-capitalize"
    body-class="max-h-325px"
    ref="chat-filter-modal"
  >
    <!-- begin:: Modal Body -->
    <b-form-group
      class="mb-0"
      :label="$t('label.role')"
      v-slot="{ ariaDescribedby }"
    >
      <b-form-checkbox
        v-for="option in options"
        v-model="selectedRoles"
        :key="option.value"
        :value="option.value"
        :aria-describedby="ariaDescribedby"
        name="role"
        inline
        class="pb-3"
      >
        {{ option.text }}
      </b-form-checkbox>
    </b-form-group>
    <!-- end:: Modal Body -->

    <!-- begin:: Modal Footer -->
    <template #modal-footer>
      <b-button class="mr-2" variant="white" @click="actionClearFilter">{{
        $t('label.clear')
      }}</b-button>

      <b-button variant="primary" @click="actionApplyFilter">{{
        $t('label.apply')
      }}</b-button>
    </template>
    <!-- end:: Modal Footer -->
  </b-modal>
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import { CONSTANTS_CONSTANTS } from '@/core/store/constants.module';
  import _ from 'lodash';
  export default {
    name: 'UserPackageDetailsModal',
    mixins: [commonMixin],
    components: {},
    props: {},
    data: () => ({
      modal: false,
      selectedRoles: [],
      options: {}
    }),
    computed: {},
    watch: {
      modal() {
        this.modal
          ? uiHelper.freezeBodyOverflowY()
          : uiHelper.unFreezeBodyOverflowY();
      }
    },
    methods: {
      actionClearFilter() {
        this.resetForm();
        this.$emit('search-chat-list');
        this.modal = false;
      },
      actionApplyFilter() {
        this.$emit('search-chat-list');
        this.modal = false;
      },
      resetForm() {
        this.selectedRoles = [];
      }
    },
    created: function() {
      let roles = _.get(
        this,
        `$store.getters.${CONSTANTS_CONSTANTS}.userRole`,
        []
      ).map((i) => i.value);

      roles.forEach((role) => {
        var roleOption = {};
        roleOption.text = i18nHelper.getMessage('enumRoleType.' + role);
        roleOption.value = role;

        this.options[role] = roleOption;
      });
    }
  };
</script>

<style lang="scss"></style>
