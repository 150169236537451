var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade-in-up"}},[_c('b-card',{key:_vm.selectedUser.userId,staticClass:"chat-messages-card",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-row',{staticClass:"justify-content-between",attrs:{"no-gutters":""}},[(_vm.selectedUser)?_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[(_vm.selectedUser.headshotFile.mediaPath)?_c('b-avatar',{attrs:{"size":"3.5em","src":_vm.selectedUser.headshotFile.mediaPath}}):_c('b-avatar',{attrs:{"size":"3.5em","text":_vm.getShortName(_vm.selectedUser.displayName)}})],1),_c('div',{staticClass:"pl-5"},[_c('div',{staticClass:"font-weight-bold h6 mb-2 text-decoration-none text-secondary text-hover-primary"},[_vm._v(" "+_vm._s(_vm.selectedUser.displayName)+" ")]),_c('div',{staticClass:"font-weight-bold h7 mb-2 text-decoration-none text-secondary"},[_vm._v(" "+_vm._s(_vm.selectedUser.companyName || '-')+" ")]),_c('div',{staticClass:"font-weight-bold h7 mb-2 text-decoration-none text-muted"},[_vm._v(" "+_vm._s(_vm.$t(("enumRoleType." + (_vm.selectedUser.role))))+" ")])])]):_vm._e()])]},proxy:true}])},[(_vm.value.chatGroupMessage.length > 0)?_c('div',{key:_vm.value.chatGroupMessage.length,staticClass:"chat-messages-card__messages_wrapper pr-5"},[_vm._l((_vm.value.chatGroupMessage),function(item,index){return [_c('div',{key:index,staticClass:"d-flex mb-10",class:[
            _vm.getUserPosition(item) == 'left'
              ? 'justify-content-start'
              : 'justify-content-end'
          ]},[_c('div',{staticClass:"d-flex flex-column",class:[
              _vm.getUserPosition(item) == 'left'
                ? 'align-items-start'
                : 'align-items-end'
            ]},[_c('div',{staticClass:"d-flex align-items-center mb-3"},[_c('div',{staticClass:"d-flex",class:[
                  _vm.getUserPosition(item) == 'left' ? 'order-2' : 'order-1'
                ]},[_c('span',{staticClass:"text-muted",class:[
                    _vm.getUserPosition(item) == 'left' ? 'order-2' : 'order-1'
                  ]},[_vm._v(_vm._s(item.createdAt))])])]),(
                [_vm.chatMessageType.TEXT, _vm.chatMessageType.INFO].includes(
                  item.chatMessageType
                )
              )?_c('div',{staticClass:"p-5 rounded text-dark max-w-400px font-weight-bold",class:[
                _vm.getUserPosition(item) == 'left'
                  ? 'text-left bg-light-primary '
                  : 'text-right bg-light-secondary '
              ]},[_vm._v(" "+_vm._s(item.message || ("(" + (_vm.$t('message.systemNoMessageDisplayed')) + ")"))+" ")]):_c('div',{staticClass:"p-5 rounded text-dark max-w-400px",class:[
                _vm.getUserPosition(item) == 'left'
                  ? 'text-left bg-light-primary'
                  : 'text-right bg-light-secondary'
              ]},[_c('ChatHistoryAttachment',{attrs:{"value":item.media}})],1)])])]})],2):_c('div',{staticClass:"text-center chat-messages-card__messages_wrapper"},[_c('span',[_vm._v(_vm._s(_vm.$t('message.thereAreNoMessagesToShow')))])]),_c('hr'),_c('div',{staticClass:"message-card-footer font-weight-bold h6 my-2 text-decoration-none text-secondary"},[(_vm.checkAccessRight(_vm.module.ADMIN_CHAT, [_vm.action.ADMIN_CHAT_UPDATE]))?_c('b-input-group',{staticClass:"mb-5",attrs:{"size":"lg"}},[_c('b-form-file',{ref:"attachment",staticStyle:{"display":"none"},attrs:{"id":"chat_attachment","accept":".pdf, .png, .jpeg, .jpg"},model:{value:(_vm.mediaFile),callback:function ($$v) {_vm.mediaFile=$$v},expression:"mediaFile"}}),_c('b-form-input',{ref:"message",attrs:{"type":"text","disabled":_vm.disableText},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.sendMessage.apply(null, arguments)}},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"secondary"},on:{"click":_vm.addAttachment}},[_c('i',{staticClass:"flaticon-attachment p-0"})]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.sendMessage}},[_vm._v(" "+_vm._s(_vm.$t('label.send'))+" ")])],1)],1):_vm._e(),(_vm.mediaFile)?_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-input-group-text',{on:{"click":_vm.addAttachment}},[_vm._v(" "+_vm._s(_vm.mediaFile.name)+" ")]),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"secondary"},on:{"click":_vm.removeAttachment}},[_c('i',{staticClass:"flaticon2-cancel p-0"})])],1)],1):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }