<template>
  <b-card class="chat-users-list-card">
    <b-card-title class="h3">{{ $t('label.liveChat') }}</b-card-title>
    <div class="font-weight-bold h6 mb-2 text-decoration-none text-secondary">
      <b-input-group>
        <b-form-input type="text" ref="keyword" v-model="keyword"></b-form-input>
        <b-input-group-append>
          <b-button variant="secondary" @click="openChatFilterModal">
            <i class="flaticon2-dashboard p-0"></i>
          </b-button>
          <b-button variant="primary" @click="searchChatList">
            <i class="flaticon2-magnifier-tool p-0"></i>
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
    <b-list-group
      class="chat-users-list-list-group"
      @scroll.passive="handleOnScroll"
    >
      <transition-group name="fade-in-up">
        <b-list-group-item
          v-for="(item, index) in value"
          href="#"
          :key="index"
          class="d-flex flex-column align-items-left text-left py-4 pr-4"
          :class="$parent.selectedUser.userId == item.userId ? 'bg-light-primary' : null"
          @click="setSelectedChatGroup(item)"
        >
          <b-row>
            <!-- begin:: Avatar -->
            <b-col cols="3">
              <b-avatar
                v-if="item.headshotFile.mediaPath"
                size="4em"
                :src="item.headshotFile.mediaPath"
              ></b-avatar>
              <b-avatar
                v-else
                size="4em"
                :text="getShortName(item.displayName)"
              ></b-avatar>
            </b-col>
            <!-- end:: Avatar -->
            <!-- begin:: Details -->
            <b-col cols="6">
              <div
                class="font-weight-bold h6 mb-2 text-decoration-none text-secondary"
              >
                {{ item.displayName }}
                <b-badge
                  v-if="item.unreadMessageCount > 0"
                  class="ml-1"
                  variant="primary"
                  pill
                >
                  {{ item.unreadMessageCount }}
                </b-badge>
              </div>
              <div
                class="font-weight-bold h7 mb-2 text-decoration-none text-secondary"
              >
                {{ item.companyName || '-' }}
              </div>
              <div
                class="font-weight-bold h7 mb-2 text-decoration-none text-muted"
              >
                {{ $t(`enumRoleType.${item.role}`) }}
              </div>
              <div class="font-weight-bold text-gray-600 last-message">
                {{ item.lastMessage || '&nbsp;' }}
              </div>
            </b-col>
            <!-- begin:: Details -->
            <b-col cols="3">
              <b-button
                :right="true"
                :absolute="true"
                :variant="item.isFavourite ? 'primary' : 'secondary'"
                class="btn-hover-primary"
                title="Pin Chat"
                @click="updateChatFavourite(item)"
              >
                <BIconPinAngleFill></BIconPinAngleFill>
              </b-button>
            </b-col>
          </b-row>
        </b-list-group-item>
      </transition-group>
    </b-list-group>
  </b-card>
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';
  import { ROUTE_NAME } from '@/core/constants';
  import { BIconPinAngleFill } from 'bootstrap-vue';

  export default {
    name: 'ChatUserList',
    components: {
      BIconPinAngleFill
    },
    mixins: [commonMixin],
    props: {
      value: {
        type: Array,
        default: () => {
          return [];
        }
      }
    },
    data: () => ({
      routeName: ROUTE_NAME,
      selectedId: null,
      keyword: null
    }),
    methods: {
      handleOnScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
        if (scrollTop + clientHeight >= scrollHeight) {
          let pagination = this.$store.state.chat.chats.pagination;

          if (pagination.currentPage != pagination.lastPage) {
            let data = {
              keyword: this.$refs.keyword.localValue,
              roles: this.$parent.$refs['chat-filter-component'].selectedRoles,
              per_page: 10,
              page: pagination.currentPage + 1
            };
            this.$parent.getChatList(data);
          }
        }
      },
      updateChatFavourite(user) {
        if (!user.chatGroupId) {
          this.$parent.isUpdateChatFavourite = true;
          let data = {
            message: '',
            mediaFile: '',
            userId: user.userId
          };
          this.$parent.initiateChat(data);
          return;
        }
        let data = {
          id: user.chatGroupId,
          action: user.isFavourite ? 'remove' : 'add'
        };
        this.$parent.updateChatFavourite(data);
      },
      searchChatList() {
        this.$emit('search-chat-list');
      },
      openChatFilterModal() {
        this.$parent.openChatFilterModal();
      },
      setSelectedChatGroup(user) {
        this.$parent.setSelectedChatGroup(user);
        user.unreadMessageCount = 0;
      },
      getUserDetails(item) {
        return {
          id: item.userId,
          name: item.displayName
        };
      },
      getShortName(name) {
        let nameArray = name ? name.split(' ') : '';
        let shortName = '';

        if (name) {
          if (nameArray.length > 1) {
            shortName = nameArray[0].charAt(0) + nameArray[1].charAt(0);
          } else {
            shortName = nameArray[0].charAt(0);
          }
        }

        return shortName;
      }
    }
  };
</script>

<style lang="scss">
  .chat-users-list-card {
    .chat-users-list-list-group {
      max-height: 550px;
      overflow: auto;
    }
    .last-message {
      overflow: hidden;
      max-height: 20px;
    }
    height: 700px;
  }
</style>
