<template>
  <div class="chat-live">
    <b-row no-gutters class="chat-live__row">
      <b-col cols="4">
        <!-- begin:: User Card -->
        <ChatUsersList
          :value="users"
          @search-chat-list="searchChatList"
          ref="chat-users-list"
        ></ChatUsersList>
        <!-- end:: User Card -->
      </b-col>
      <b-col cols="8" class="pl-8">
        <!-- begin:: Messages Card -->
        <transition name="fade-in-up">
          <ChatMessagesCard
            v-if="Object.keys(selectedUser).length > 0"
            :value="chatObj"
            :selectedUser="selectedUser"
            ref="chat-messages-card"
          ></ChatMessagesCard>
          <!-- end:: Messages Card -->
        </transition>
      </b-col>
    </b-row>

    <!--begin:: Chat Filter Modal-->
    <ChatFilterModal
      v-model="showChatFilter"
      ref="chat-filter-component"
      @search-chat-list="searchChatList"
    ></ChatFilterModal>
    <!--end:: Chat Filter Modal-->
  </div>
</template>

<script>
  import ChatUsersList from '@/views/components/chat/ChatUsersList';
  import ChatMessagesCard from '@/views/components/chat/ChatMessagesCard';
  import ChatFilterModal from '@/views/components/chat/ChatFilterModal';
  import commonMixin from '@/core/mixins/common.mixin';
  import { ROUTE_NAME } from '@/core/constants';
  import { i18nHelper, Configuration } from '@/core/utils';
  import {
    CHAT_GET_CHAT_LIST,
    CHAT_GET_CHAT_DETAILS,
    CHAT_INITIATE_CHAT,
    CHAT_UPDATE_CHAT_FAVOURITE,
    CHAT_INITIAL_INITIATE_CHAT_STATE,
    CHAT_INITIAL_GET_CHAT_DETAILS_STATE,
    CHAT_INITIAL_GET_CHAT_LIST_STATE,
    CHAT_INITIAL_UPDATE_CHAT_FAVOURITE_STATE
  } from '@/core/store/chat.module';
  const { io } = require('socket.io-client');
  var sock;
  var publisherUrl = Configuration.value('publisherUrl');

  export default {
    name: 'ChatLive2',
    components: {
      ChatUsersList,
      ChatMessagesCard,
      ChatFilterModal
    },
    mixins: [commonMixin],
    data: () => ({
      id: null,
      showChatFilter: false,
      isUpdateChatFavourite: false,
      users: [],
      selectedUser: {},
      chatObj: {
        chatGroup: {},
        chatGroupMessage: []
      }
    }),
    computed: {
      chatListComplete() {
        return this.$store.state.chat.chats.complete;
      },
      chatComplete() {
        return this.$store.state.chat.chatDetails.complete;
      },
      initiateChatComplete() {
        return this.$store.state.chat.chatInitiateChat.complete;
      },
      updateChatFavouriteComplete() {
        return this.$store.state.chat.chatUpdateChatFavourite.complete;
      }
    },
    watch: {
      chatListComplete() {
        let response = this.$store.state.chat.chats;
        let title = i18nHelper.getMessage('label.liveChat');
        let initialStateAction = CHAT_INITIAL_GET_CHAT_LIST_STATE;
        let successAction = (response) => {
          this.getChatListSucceedAction(response);
        };
        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      chatComplete() {
        let response = this.$store.state.chat.chatDetails;
        let title = i18nHelper.getMessage('label.getChat');
        let initialStateAction = CHAT_INITIAL_GET_CHAT_DETAILS_STATE;
        let successAction = (response) => {
          this.getChatSucceedAction(response);
        };
        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      initiateChatComplete() {
        let response = this.$store.state.chat.chatInitiateChat;
        let title = i18nHelper.getMessage('label.liveChat');
        let initialStateAction = CHAT_INITIAL_INITIATE_CHAT_STATE;
        let successAction = (response) => {
          this.initiateChatCompleteAction(response);
        };
        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      updateChatFavouriteComplete() {
        let response = this.$store.state.chat.chatUpdateChatFavourite;
        let title = i18nHelper.getMessage('label.liveChat');
        let initialStateAction = CHAT_INITIAL_UPDATE_CHAT_FAVOURITE_STATE;
        let successAction = () => {
          this.searchChatList();
        };
        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      }
    },
    created() {
      this.initChatLive();
      sock = io(publisherUrl, {
        query: {
          admin_user_id: this.$store.state.auth.me.data.id
        }
      });
      var newMessageReceivedAction = this.newMessageReceivedAction;
      sock.on('admin-channel:AdminChat', function (data) {
        newMessageReceivedAction(data);
      });
    },
    methods: {
      newMessageReceivedAction(data) {
        this.searchChatList();
        if (data.chat_group_id == this.selectedUser.chatGroupId) {
          this.getChat(this.selectedUser.chatGroupId);
        }
      },
      setSelectedChatGroup(user) {
        this.selectedUser = {
          ...user
        };

        this.chatObj = {
          chatGroup: {},
          chatGroupMessage: []
        };

        if (this.selectedUser.chatGroupId) {
          this.getChat(user.chatGroupId);
        }
      },
      getChatListSucceedAction(response) {
        let data = response.data;

        this.users = data;
      },
      getChatSucceedAction(response) {
        let data = response.data;

        this.chatObj = {
          ...data
        };
      },
      searchChatList() {
        // this.users = [];

        let data = {
          keyword: this.$refs['chat-users-list'].keyword,
          roles: this.$refs['chat-filter-component'].selectedRoles,
          per_page: 10,
          page: 1
        };

        this.getChatList(data);
      },
      getChatList(data) {
        this.$store.dispatch(CHAT_GET_CHAT_LIST, { data });
      },
      getChat(id) {
        this.$store.dispatch(CHAT_GET_CHAT_DETAILS, { id });
      },
      openChatFilterModal() {
        this.showChatFilter = true;
        this.$refs['chat-filter-component'].$refs['chat-filter-modal'].show();
      },
      initiateChat(data) {
        this.$store.dispatch(CHAT_INITIATE_CHAT, { data });
      },
      initiateChatCompleteAction(response) {
        this.selectedUser.chatGroupId = response.data.chatGroupId;
        // this.$refs['chat-messages-card'].resetField();

        if (this.isUpdateChatFavourite) {
          this.isUpdateChatFavourite = false;
          let data = {
            id: this.selectedUser.chatGroupId,
            action: this.selectedUser.isFavourite ? 'remove' : 'add'
          };
          this.updateChatFavourite(data);
          return;
        }

        this.searchChatList();
        this.getChat(this.selectedUser.chatGroupId);
      },
      updateChatFavourite(data) {
        this.$store.dispatch(CHAT_UPDATE_CHAT_FAVOURITE, { data });
      },
      initChatLive() {
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.chat'),
              route: { name: ROUTE_NAME.CHAT }
            },
            { title: i18nHelper.getMessage('label.liveChat') }
          ],
          actions: []
        };

        this.initBreadCrumb(breadcrumbData);
        window.KTUtil.scrollTop();

        // Initialize user list
        let data = {
          keyword: '',
          roles: [],
          per_page: 10,
          page: 1
        };

        this.getChatList(data);
      }
    }
  };
</script>

<style lang="scss"></style>
