<template>
  <transition name="fade-in-up">
    <b-card class="chat-messages-card" :key="selectedUser.userId">
      <!-- begin:: Card Header -->
      <template #header>
        <b-row no-gutters class="justify-content-between">
          <div v-if="selectedUser" class="d-flex align-items-center">
            <!-- begin:: Avatar -->
            <div>
              <b-avatar
                v-if="selectedUser.headshotFile.mediaPath"
                size="3.5em"
                :src="selectedUser.headshotFile.mediaPath"
              ></b-avatar>
              <b-avatar
                v-else
                size="3.5em"
                :text="getShortName(selectedUser.displayName)"
              ></b-avatar>
            </div>
            <!-- end:: Avatar -->
            <!-- begin:: Details -->
            <div class="pl-5">
              <div
                class="font-weight-bold h6 mb-2 text-decoration-none text-secondary text-hover-primary"
              >
                {{ selectedUser.displayName }}
              </div>
              <div
                class="font-weight-bold h7 mb-2 text-decoration-none text-secondary"
              >
                {{ selectedUser.companyName || '-' }}
              </div>
              <div
                class="font-weight-bold h7 mb-2 text-decoration-none text-muted"
              >
                {{ $t(`enumRoleType.${selectedUser.role}`) }}
              </div>
            </div>
            <!-- begin:: Details -->
          </div>
        </b-row>
      </template>
      <!-- end:: Card Header -->

      <!-- begin:: Card Body -->
      <div
        v-if="value.chatGroupMessage.length > 0"
        :key="value.chatGroupMessage.length"
        class="chat-messages-card__messages_wrapper pr-5"
      >
        <template v-for="(item, index) in value.chatGroupMessage">
          <div
            :key="index"
            class="d-flex mb-10"
            :class="[
              getUserPosition(item) == 'left'
                ? 'justify-content-start'
                : 'justify-content-end'
            ]"
          >
            <div
              class="d-flex flex-column"
              :class="[
                getUserPosition(item) == 'left'
                  ? 'align-items-start'
                  : 'align-items-end'
              ]"
            >
              <div class="d-flex align-items-center mb-3">
                <!-- begin:: User -->
                <div
                  class="d-flex"
                  :class="[
                    getUserPosition(item) == 'left' ? 'order-2' : 'order-1'
                  ]"
                >
                  <span
                    class="text-muted"
                    :class="[
                      getUserPosition(item) == 'left' ? 'order-2' : 'order-1'
                    ]"
                    >{{ item.createdAt }}</span
                  >
                </div>
                <!-- end:: User -->
              </div>
              <!-- begin:: Message -->
              <div
                v-if="
                  [chatMessageType.TEXT, chatMessageType.INFO].includes(
                    item.chatMessageType
                  )
                "
                class="p-5 rounded text-dark max-w-400px font-weight-bold"
                :class="[
                  getUserPosition(item) == 'left'
                    ? 'text-left bg-light-primary '
                    : 'text-right bg-light-secondary '
                ]"
              >
                {{
                  item.message || `(${$t('message.systemNoMessageDisplayed')})`
                }}
              </div>
              <div
                v-else
                class="p-5 rounded text-dark max-w-400px"
                :class="[
                  getUserPosition(item) == 'left'
                    ? 'text-left bg-light-primary'
                    : 'text-right bg-light-secondary'
                ]"
              >
                <ChatHistoryAttachment
                  :value="item.media"
                ></ChatHistoryAttachment>
              </div>
              <!-- end:: Message -->
            </div>
          </div>
        </template>
      </div>
      <div v-else class="text-center chat-messages-card__messages_wrapper">
        <span>{{ $t('message.thereAreNoMessagesToShow') }}</span>
      </div>
      <hr>
      <div
        class="message-card-footer font-weight-bold h6 my-2 text-decoration-none text-secondary"
      >
        <b-input-group size="lg" class="mb-5" v-if="checkAccessRight(module.ADMIN_CHAT, [action.ADMIN_CHAT_UPDATE])">
          <b-form-file
            style="display:none;"
            ref="attachment"
            id="chat_attachment"
            v-model="mediaFile"
            accept=".pdf, .png, .jpeg, .jpg"
          ></b-form-file>
          <b-form-input
            type="text"
            v-model="message"
            v-on:keyup.enter="sendMessage"
            :disabled="disableText"
            ref="message"
          ></b-form-input>
          <b-input-group-append>
            <b-button variant="secondary" @click="addAttachment">
              <i class="flaticon-attachment p-0"></i>
            </b-button>
            <b-button variant="primary" @click="sendMessage">
              {{ $t('label.send') }}
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <b-input-group size="sm" v-if="mediaFile">
          <b-input-group-text @click="addAttachment">
            {{ mediaFile.name }}
          </b-input-group-text>
          <b-input-group-append>
            <b-button variant="secondary" @click="removeAttachment">
              <i class="flaticon2-cancel p-0"></i>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </div>

      <!-- end:: Card Body -->
    </b-card>
  </transition>
</template>

<script>
  import ChatHistoryAttachment from '@/views/components/chat-history/ChatHistoryAttachment';
  import { MediaExtensionType } from '@/core/constants/enums';
  import { CHAT_MESSAGE_TYPE } from '@/core/constants/enums';
  import commonMixin from '@/core/mixins/common.mixin';
  import { i18nHelper } from '@/core/utils';
  import {
    CHAT_SEND_TEXT_MESSAGE,
    CHAT_SEND_ATTACHMENT,
    CHAT_INITIAL_SEND_TEXT_MESSAGE_STATE,
    CHAT_INITIAL_SEND_ATTACHMENT_STATE
  } from '@/core/store/chat.module';
  import { MODULE, ACTION } from '@/core/constants';

  export default {
    name: 'ChatMessagesCard',
    mixins: [commonMixin],
    components: {
      ChatHistoryAttachment
    },
    props: {
      value: {
        type: Object,
        default: () => {
          return {
            chatGroup: {}
          };
        }
      },
      selectedUser: {
        type: Object,
        default: () => {
          return {};
        }
      }
    },
    data: () => ({
      message: '',
      mediaFile: null,
      disableText: false,
      chatMessageType: CHAT_MESSAGE_TYPE,
      module: MODULE,
      action: ACTION,
    }),
    updated: function() {
      var container = this.$el.querySelector(
        '.chat-messages-card__messages_wrapper'
      );
      container.scrollTop = container.scrollHeight;
    },
    computed: {
      sendTextMessageComplete() {
        return this.$store.state.chat.chatSendTextMessage.complete;
      },
      sendAttachmentComplete() {
        return this.$store.state.chat.chatSendAttachment.complete;
      }
    },
    watch: {
      sendTextMessageComplete() {
        let response = this.$store.state.chat.chatSendTextMessage;
        let title = i18nHelper.getMessage('label.liveChat');
        let initialStateAction = CHAT_INITIAL_SEND_TEXT_MESSAGE_STATE;
        let successAction = (response) => {
          this.sendMessageCompleteAction(response);
        };
        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      sendAttachmentComplete() {
        let response = this.$store.state.chat.chatSendAttachment;
        let title = i18nHelper.getMessage('label.liveChat');
        let initialStateAction = CHAT_INITIAL_SEND_ATTACHMENT_STATE;
        let successAction = (response) => {
          this.sendMessageCompleteAction(response);
        };
        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      mediaFile() {
        this.disableText = false;
        if (this.mediaFile) {
          this.disableText = true;
        }
      }
    },
    methods: {
      addAttachment() {
        document.getElementById('chat_attachment').click();
      },
      removeAttachment() {
        this.mediaFile = null;
      },
      initiateChat() {
        let data = {
          message: this.message,
          mediaFile: this.mediaFile,
          userId: this.selectedUser.userId
        };
        this.$parent.initiateChat(data);
      },
      sendMessage() {
        if (!this.mediaFile && !this.message) {
          return;
        }
        if (!this.selectedUser.chatGroupId) {
          this.initiateChat();
        } else if (this.mediaFile) {
          this.sendAttachment();
        } else {
          this.sendTextMessage();
        }
      },
      sendTextMessage() {
        let data = {
          message: this.message,
          id: this.selectedUser.chatGroupId
        };
        this.$store.dispatch(CHAT_SEND_TEXT_MESSAGE, { data });
      },
      sendAttachment() {
        var fileExtension = this.mediaFile.name.split('.').pop().toUpperCase();
        
        let data = {
          mediaFile: this.mediaFile,
          mediaType: MediaExtensionType[fileExtension],
          id: this.selectedUser.chatGroupId
        };
        this.$store.dispatch(CHAT_SEND_ATTACHMENT, { data });
      },
      sendMessageCompleteAction() {
        this.$parent.searchChatList();
        this.$parent.getChat(this.selectedUser.chatGroupId);
        this.resetField();
      },
      resetField() {
        this.message = '';
        this.mediaFile = null;
      },
      getUserPosition(detail) {
        let position = 'left';
        if (detail.adminUserId == this.$store.state.auth.me.data.id) {
          position = 'right';
        }
        return position;
      },
      getShortName(name) {
        let nameArray = name ? name.split(' ') : '';
        let shortName = '';

        if (name) {
          if (nameArray.length > 1) {
            shortName = nameArray[0].charAt(0) + nameArray[1].charAt(0);
          } else {
            shortName = nameArray[0].charAt(0);
          }
        }

        return shortName;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .chat-messages-card {
    .chat-messages-card__messages_wrapper {
      max-height: 370px;
      overflow: auto;
    }
    height: 700px;
    .message-card-footer {
      height: 80px;
    }
  }
  ::v-deep .card-header {
    padding: 10px !important;
  }
</style>
